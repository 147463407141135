import React, { useContext, useEffect, useState } from 'react';

import marker from './Marker';
import moment from 'moment';

import { StationContext } from '../../contexts/StationContext';
import { FlifoContext } from '../../contexts/FlifoContext';
import { MapContext } from "../Map/MapContext";
import { WorkloadContext } from '../../contexts/WorkloadContext';

import { processLocations, findLeavingAircrafts, GetMrdStatus } from '../../utils/utilities';
import { MAP_INIT_STYLES } from '../../utils/constants';

const Map = () => {  
	const { workArea, stationProfile, setIsDashboardVisible,loadStationProfile } = useContext(StationContext);
	var { flifo, updateFlifo, flifoCount, updateFlifoCount, flifoByStation } = useContext(FlifoContext);
	const { setDashboardMap, setSelectedFlight, map, zoomlevel, setZoomlevel ,zoomlevelLP, setZoomlevelLP,home,setHome,station,setStation} = useContext(MapContext);
	const { getWorkload, getApuInformation,  } = useContext(WorkloadContext);

	const [previousFlifo, setPreviousFlifo] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [searchedAircraft, setSearchedAircraft] = useState(null);

	const [offset, setOffset] = useState(null);
	useEffect(() => {
		if (stationProfile && stationProfile.stations && stationProfile.currentStation) {
			const stationOffset = stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation) ? stationProfile.stations.find(s => s.stationCode === stationProfile.currentStation).gmtOffset : null;

			stationOffset ? setOffset(stationOffset) : setOffset(null);
		}
		if (stationProfile && stationProfile.stations) {
			var stations = [];
			stationProfile.stations.forEach(s => {
				stations.push(s.stationCode);
			});
			updateFlifoCount(stations);
			const interval = setInterval(() => updateFlifoCount(stations), 300000);
			return () => clearInterval(interval);
		}
	}, [stationProfile])

	useEffect(() => {
		if (stationProfile !== null && !stationProfile.isValidStation && flifoCount.length > 0 && zoomlevel && flifo.length === 0) {
			loadLandingPage();
		}
		if(home){
			loadLandingPage();
		}
	}, [flifoCount, stationProfile,zoomlevel,zoomlevelLP]);

	const loadLandingPage = (type) => {
		let localMarkers = markers;
	localMarkers.forEach(element => {
		element.remove();
	});
		 localMarkers = [];
		stationProfile.stations.forEach(s => {
			let dataDisplay=``;
					let totalCount;
					let storedCount;
					let parkedCount;
					let unroutedCount;
					let ronCount;
					let starCount;
					let oosWCount;
					let oosNCount;
					let mrdCount;
					let locStatTime;					
					flifoCount.forEach(f => {
						if (s.stationCode === f.station) {
							totalCount = f.totalAircraftCount;
							unroutedCount = f.unroutedAircraftCount;
							parkedCount = f.parkedAircraftCount;
							storedCount = f.storedAircraftCount;
							ronCount = f.ronAircraftCount;
							starCount = f.starAircraftCount;
							oosWCount = f.oOSWideBody;
							oosNCount = f.oOSNarrowBody;
							mrdCount = f.mrdCount;
							var timeArr = f.localDateTime.split('T');
							locStatTime = timeArr[1].split(":")[0]+":"+timeArr[1].split(":")[1];
						}
					})

					let stored = `${process.env.PUBLIC_URL}/images/storedplane.svg`;
					let allFlight = `${process.env.PUBLIC_URL}/images/unroutedplane.svg`;
					let ron = `${process.env.PUBLIC_URL}/images/ronwhite.svg`;
					let line = `${process.env.PUBLIC_URL}/images/line.svg`;
					let linedown =  `${process.env.PUBLIC_URL}/images/linedown.svg`;
					let linemco =  `${process.env.PUBLIC_URL}/images/linemco.svg`;
					let star = `${process.env.PUBLIC_URL}/images/starwhite.svg`;
					let workloadImage =`${process.env.PUBLIC_URL}/images/wrench-red-lp.svg`;
					let clock = `${process.env.PUBLIC_URL}/images/clock.svg`;
					let weather = `${process.env.PUBLIC_URL}/images/weather.svg`;
					let lineType = line;
					let lineImg = 'lineImg' + s.stationCode;
					if(MAP_INIT_STYLES['lineStations'].includes(s.stationCode)){
						lineType= line;
					}else if(MAP_INIT_STYLES['line1Stations'].includes(s.stationCode)){
						lineType = linemco;
					}else{
						lineType = linedown;
					}
					let pos = '';
					if(s.stationCode ==='MCO'){
						pos = 'pos'+s.stationCode;
					}
				
			if(zoomlevelLP !== 'lp'){
				//for TPA station change zoom to mega for LP
				if(zoomlevel === 'max'){
					setZoomlevel('mega');
				}
				switch (zoomlevel) {
				case 'max': 
				dataDisplay = `<div>
				<div class="marker-s gate-s-name">
				  <span class="marker-dot"></span>
				  <span class="gate-s-color">`+ s.stationCode + `</span>
				</div>`
				break;
				case 'mega':
					dataDisplay = `<div>
					<div class = "line2 `+pos+` ">
					<div class='marker-dot1'>
					  <div class="marker-dot2"></div>
					</div>
					</div>
					<div class=`+lineImg+`>
					<img   src=`+ lineType + ` >
					</div>
					<div class='circle-container1 `+s.stationCode+`'>	
					<span class='marker-circle degOos'>
						<div class='oosClass'>
					  <div class='oosText'>OOS</div>
					  <div class='oosText1'>WB/NB:</div>
					  <div class='oosCount'>`+ oosWCount + `/` + oosNCount + `</div>
						</div>
				  </span>				  
				  <span class='marker-circle3 degUnr'>
				  <div class='unroutedText'>
					<div class = 'unText'>Unr.</div>
					<div class='unroutedCount'>`+ unroutedCount + `</div>
				  </div>
				</span>
				  <span class='marker-circle3 degRon'>
					<div class='unroutedText'>
					  <div class = 'unText'><img src=`+ ron + ` ></div>
					  <div class='unroutedCount'>`+ ronCount + `</div>
					</div>
				  </span>				 
				  <span class='marker-circle3 degStar'>
					<div class='unroutedText'>
					  <div class = 'unText'>
						<img src=`+ star + ` >
					  </div>
					  <div class='unroutedCount'>`+ starCount + `</div>
					</div>
				  </span>				  
					<div class='circle-container2 circle2 line1'>
					<div class='center'>
					<div class ='stationName'>
					`+s.stationCode+`
					</div>
					<div class ='wthr'>
					<div>
					<img id="clock" class="clock" src="`+clock+`">`+locStatTime+`
					</div>
					<div>
	</div>
					</div>
					<div class='totalFlights'>
					<div class='totFlightText1'>
					  <div class='totText'>Total</div>
					  <div class='totCount1'>`+ totalCount + `</div>
					</div>
					<div class = 'separator'></div> 
					<div class='totFlightText2'>
					<div class='mrdTotal'>
					<div>
					<img id="plane" class="workload-bg" src="`+ workloadImage + `">
					</div>
						<div class='mrdText'>
						MRD
					</div>
					</div>
						<div class='totCount2'>`+mrdCount+`</div>
					  </div>
				  </div> 				  
					
				  </div>
					</div>
								  
					</div>
									  </div>`

				break;
				case 'mini': 
				dataDisplay = `<div>
				<div class = "line2 `+pos+` ">
				<div class='marker-dot1'>
				  <div class="marker-dot2"></div>
				</div>
				</div>
				<div class=`+lineImg+`>
				<img   src=`+ lineType + ` >
				</div>
				<div class='circle-container1 `+s.stationCode+`'>	
				<span class='marker-circle degOos'>
					<div class='oosClass'>
				  <div class='oosText'>OOS</div>
				  <div class='oosText1'>WB/NB:</div>
				  <div class='oosCount'>`+ oosWCount + `/` + oosNCount + `</div>
					</div>
			  </span>	
			  <span class='marker-circle3 degUnr'>
			  <div class='unroutedText'>
				<div class = 'unText'>Unr.</div>
				<div class='unroutedCount'>`+ unroutedCount + `</div>
			  </div>
			</span>
			  <span class='marker-circle3 degRon'>
				<div class='unroutedText'>
				  <div class = 'unText'><img src=`+ ron + ` ></div>
				  <div class='unroutedCount'>`+ ronCount + `</div>
				</div>
			  </span>				 
			  <span class='marker-circle3 degStar'>
				<div class='unroutedText'>
				  <div class = 'unText'>
					<img src=`+ star + ` >
				  </div>
				  <div class='unroutedCount'>`+ starCount + `</div>
				</div>
			  </span>				  
				<div class='circle-container2 circle2 line1'>
				<div class='center'>
				<div class ='stationName'>
				`+s.stationCode+`
				</div>
				<div class ='wthr'>
				<div>
				<img id="clock" class="clock" src="`+clock+`">`+locStatTime+`
				</div>
				<div>
			</div>
				</div>
				<div class='totalFlights'>
				<div class='totFlightText1'>
				  <div class='totText'>Total</div>
				  <div class='totCount1'>`+ totalCount + `</div>
				</div>
				<div class = 'separator'></div> 
				<div class='totFlightText2'>
				<div class='mrdTotal'>
				<div>
				<img id="plane" class="workload-bg" src="`+ workloadImage + `">
				</div>
					<div class='mrdText'>
					MRD
				</div>
				</div>
				<div class='totCount2'>`+mrdCount+`</div>
				</div>
			  </div> 				  
				
			  </div>
				</div>
							  
				</div>
								  </div>`

			break;
			default: break;
			}
			}else{
				dataDisplay = `
				<div>
				<div class="pin2"></div>
				<div class = "map-marker">
				<div class ="mark-station">`+s.stationCode+`
				</div>
				</div>
				<div class='marker-dot1'>
				<div class="marker-dot2"></div>
			  </div>
			  </div>`
			}
			const m = marker({
				latlng: new window.google.maps.LatLng(s.lat, s.lng),
				map: map,
				html:dataDisplay
			});
			m.addListener("click", () => {
				setHome('');
				setStation('Station')
				loadStationProfile(s.stationCode);
			});
			localMarkers.push(m);
		});
		setMarkers(localMarkers);
		
	}
const getFlightImage = (isStar, isStoredAircraft, isUnroutedAircraft) => {

	return isStoredAircraft ? `${process.env.PUBLIC_URL}/images/storedplane.svg`
		: (isUnroutedAircraft ? `${process.env.PUBLIC_URL}/images/unroutedplane.svg`
			: (isStar !== "0" ? `${process.env.PUBLIC_URL}/images/bluestar.svg` : `${process.env.PUBLIC_URL}/images/plane.svg`));
}
const GetShortForm = (parkingDuration) => {
	return parkingDuration.toUpperCase() === "ACTIVE" ? "Actv" : "Prlgd";
}
const getWorkloadImage = (workloadStatus) => {
	return workloadStatus === "red-wrench" ? `${process.env.PUBLIC_URL}/images/wrench-red.svg` : workloadStatus === "green-wrench" ? `${process.env.PUBLIC_URL}/images/wrench-green.svg` : `${process.env.PUBLIC_URL}/images/wrench-grey.svg`;
}
const handleMarkerClick = (flight) => {
	getApuInformation(flight.tailNumber, flight.arrivalCity);
	getWorkload(flight.tailNumber, flight.flightNumber, flight.nextFlightNumber, flight.arrivalCity, flight.scheduleArrivalLocal, flight.estimateArrivalLocal, flight.isRon, flight.nextScheduleDepartureLocal, flight.nextEstimateDepartureLocal);
	setSelectedFlight(flight);
}

const handleStationMarkerClick = (station) => {
	var latLng = new window.google.maps.LatLng(station.lat, station.lng);
	if (map !== null) {
		map.panTo(latLng);
		map.setZoom(16);
	}
}

const isApuOn = (ac) => {
	let isApu = "N";
	if (ac.apu && ac.apu.length > 0) {
		isApu = ac.apu[0].isApuRunning ? 'H' :'N';
	}

	//let isApu = false;
	// if (ac.apu && ac.apu.length > 0 && ac.apu[0].isApuRunning && ac.apu[0].activityDate) {
	// 	const utc = moment().utc().format();
	// 	const curr = moment(utc).utcOffset(offset).format('MM/DD/YYYY HH:mm');
	// 	const activityDate = moment(ac.apu[0].activityDate);
	// 	const currentDate = moment(curr);
	// 	const minutesDiff = Math.floor((moment.duration(currentDate.diff(activityDate))).asMinutes());
	// 	isApu = minutesDiff < 20 && minutesDiff > -5;
	// }
	return isApu;
}

const isMRDBroken15 = (flight) => {
	const depDate = moment(flight.nextEstimateDepartureLocal);
	const utc = moment().utc().format();
	const currentDate = offset ? moment(utc).utcOffset(offset).format('YYYY-MM-DDTHH:mm:ss') : moment().format('YYYY-MM-DDTHH:mm:ss');
	const minutesDiff = Math.floor((moment.duration(depDate.diff(currentDate))).asMinutes());
	return (flight.mrd === null || !flight.mrd.hasMrd) && minutesDiff <= 15 ? true : false;
}
const loadMaps = () => {
	let localMarkers = markers;
	localMarkers.forEach(element => {
		element.remove();
	});
	localMarkers = [];
	if (stationProfile !== null) {
		stationProfile.workAreas.forEach(wa => {
			let Unassigned = false;
			if (wa.workAreaName === "Unassigned-Terminal" || wa.workAreaName === "Unassigned-Hangar" || wa.workAreaName === "Unassigned-Remote") {
				Unassigned = true;
			}
			const m = marker({
				latlng: new window.google.maps.LatLng(wa.lat, wa.lng),
				map: map,
				html: zoomlevel === "mini" || zoomlevel === "" || Unassigned ? `<div></div>` : `<div class="workarea-label">` + wa.workAreaName + `</div>`
			});
			localMarkers.push(m);
		});
	}
	const fullPrevious = processLocations(previousFlifo);
	// console.log('previous flifo', fullPrevious);
	const fullCurrent = processLocations(flifo);
	console.log('current flifo', fullCurrent);
	const leaving = findLeavingAircrafts(fullPrevious, fullCurrent);
	console.log('leaving aircrafts', leaving);

	const marklocs = fullCurrent.length > 0 ? [...new Set(fullCurrent.map(f => f.location.locationName))] : [];
	const stationlocs = stationProfile.locations.length > 0 ? [...new Set(stationProfile.locations.map(l => { return { locationName: l.locationName, lat: l.lat, lng: l.lng } }))] : [];

	for (let i = 0; i < marklocs.length; i++) {
		const stationIndex = stationlocs.findIndex(s => s.locationName === marklocs[i]);
		if (stationIndex !== -1) stationlocs.splice(stationIndex, 1);
	}

	stationlocs.forEach(l => {
		let displayloc = '';
		let Unassigned = false;
		if (l.locationName === "Unassigned-Terminal" || l.locationName === "Unassigned-Hangar" || l.locationName === "Unassigned-Remote") {
			Unassigned = true;
		}
		var gateCss = l.locationName.length > 4 ? 'marker-gate gate-long-name gate-only' : 'marker-gate gate-short-name gate-only';
		if (zoomlevel === 'mega') {
			displayloc = Unassigned ? `<div></div>` : `<div class="` + gateCss + `">${l.locationName}</div>`;
		}
		const m = marker({
			latlng: new window.google.maps.LatLng(l.lat, l.lng),
			map: map,
			html: displayloc
		});
		localMarkers.push(m);
	})

	flifo.forEach(ac => {
		const unknown = { locationName: null, lat: null, lng: null, direction: null };
		let location = unknown;
		if (ac.tows !== null && ac.tows !== undefined && ac.tows.length > 0) {
			if (new Date(ac.actualInLocal) > new Date(ac.tows[0].scheduledTowStartTimeLocal) && ac.location !== null) {
				location = { locationName: ac.location.locationName, lat: ac.location.lat, lng: ac.location.lng, direction: ac.location.direction };
			}
			else {
				if (ac.tows[0].location !== null && ac.tows[0].location !== undefined) {
					location = { locationName: ac.tows[0].location.locationName, lat: ac.tows[0].location.lat, lng: ac.tows[0].location.lng, direction: ac.tows[0].location.direction };
				}
				else {
					if (ac.location !== null) location = { locationName: ac.location.locationName, lat: ac.location.lat, lng: ac.location.lng, direction: ac.location.direction };
				}
			}
		}
		else location = { locationName: ac.location.locationName, lat: ac.location.lat, lng: ac.location.lng, direction: ac.location.direction };

		const isApu = isApuOn(ac);

		const latlng = new window.google.maps.LatLng(location.lat, location.lng);
		let dataDisplay = ``;
		let isStoredAircraft = ac.storageStatus !== null && ac.storageStatus !== 'Returned to Service';
		let isUnroutedAircraft = !isStoredAircraft && ac.nextScheduleDepartureLocal === null;
		let flightImage = getFlightImage(ac.nextIsStar, isStoredAircraft, isUnroutedAircraft);
		let flightImageClass = isStoredAircraft ? "stored-aircraft" : "";
		let workloadImage = getWorkloadImage(ac.workloadWrench);
		let etopsImage = isStoredAircraft ? `` : (ac.nextIsEtops ? `${process.env.PUBLIC_URL}/images/ETOPS-v2.svg` : "");
		let mrdImage = isStoredAircraft ? `` : (GetMrdStatus(ac.mrd) === "broken" ? `${process.env.PUBLIC_URL}/images/mrd-broken.svg` : `${process.env.PUBLIC_URL}/images/MRD-green.svg`);
		let ronImage = ac.isRon ? `${process.env.PUBLIC_URL}/images/bluemoon.svg` : "";
		let classBrokenMRD15 = isMRDBroken15(ac) ? "animate-circle" : "";
		let oosClass = ac.oos !== null ? "oos" : "";
		let oosClassMini = isStoredAircraft ? "stored" :
			isUnroutedAircraft ? "unrouted" : (classBrokenMRD15 || ac.oos !== null ? "oos" : "");
		let parkingDuration = isStoredAircraft && ac.parkingDuration !== null && ac.parkingDuration !== "" ? GetShortForm(ac.parkingDuration) : "";
		let mrdClass = parkingDuration !== "" ? "mrd-none" : "";

		var locdirection = location.direction !== null ? location.direction.trim() : '';
		var gateCss = location.locationName.length > 4 ? isApu !== "N" ? 'marker-gate apu-on-h gate-long-name' : 'marker-gate gate-long-name' : isApu !== "N" ? 'marker-gate apu-on-h gate-short-name' : 'marker-gate gate-short-name';

		const depDate = moment(ac.nextEstimateDepartureLocal);
		const utc = moment().utc().format();
		const currentDate = offset ? moment(utc).utcOffset(offset).format('YYYY-MM-DDTHH:mm:ss') : moment().format('YYYY-MM-DDTHH:mm:ss');
		const minutesDiff = Math.floor((moment.duration(depDate.diff(currentDate))).asMinutes());

		switch (zoomlevel) {
			case 'max':
			case 'mega':
				const markerType = locdirection === 'W' || locdirection === 'E' ? `b-marker-v back-marker-v marker-${locdirection}-${zoomlevel}` : `b-marker back-marker marker-${locdirection}-${zoomlevel}`;
				const timerCss = locdirection === 'W' || locdirection === 'E' ? 'back-timer-v' : 'back-timer';
				const markerContentCss = locdirection === 'W' || locdirection === 'E' ? 'marker-content-v' : 'marker-content';
				const frontmarker = locdirection === 'W' || locdirection === 'E' ? 'f-marker-v' : 'f-marker';
				dataDisplay = minutesDiff && minutesDiff < 61 ?
					`<div class="marker">
							<div class="`+ markerContentCss + `">
								<div class="`+ frontmarker + ` marker-` + locdirection + `-` + zoomlevel + oosClass + ` ${zoomlevel === 'mega' && searchedAircraft && searchedAircraft.tailNumber === ac.tailNumber ? 'magnify-marker' : ''}">
									<div class="main">
										<div class="`+ flightImageClass + `">
											<div class="`+ classBrokenMRD15 + `"></div>
											<img id="plane" class="flight-img" src="`+ flightImage + `">
										</div>
										<div>
											<div class="tailnumber">${ac.tailNumber}</div>
											${ac.mrd && ac.mrd.displayStatus === 'MFDI' ? `<div class='mfd issue'>MFD</div>` :
						ac.mrd && (ac.mrd.displayStatus === 'MFDAPP' || ac.mrd.displayStatus === 'MFDARR') ? `<div class='mfd broken'>MFD</div>` :
							`<div class="mrd"><img id="mrd" class="` + mrdClass + `" src="` + mrdImage + `"><span class="parking-duration">` + parkingDuration + `</span></div>`
					}
										</div>
									</div>
									<div class="extension">
										<div class="workload">
										<img id="plane" class="workload-bg" src="`+ workloadImage + `">
										</div>
										<div class="icons-container">
											<div class="icons">
											<img class="workload-bg" id="plane" src="`+ ronImage + `">
											</div>
											<div class="icons">
											<img id="etops" src="`+ etopsImage + `" />
											</div>
										</div>
									</div>											
								</div>
								<div class="`+ markerType + `">
									<div class="main">
										<div class="`+ timerCss + `">
											<div>${minutesDiff}</div> 
											<div class="min-label">min</div>
										</div>
									</div>
								</div>
							</div>
							<div class="`+ gateCss + `">${location.locationName}</div>
						</div>`
					:
					`<div class="marker">
							<div>
								<div class="marker-`+ locdirection + `-` + zoomlevel + oosClass + ` ${zoomlevel === 'mega' && searchedAircraft && searchedAircraft.tailNumber === ac.tailNumber ? 'magnify-marker' : ''}">
									
									<div class="main">
										<div class="`+ flightImageClass + `">
											<div class="`+ classBrokenMRD15 + `"></div>
											<img id="plane" src="`+ flightImage + `">
										</div>
										<div>
											<div class="tailnumber">${ac.tailNumber}</div>
											${ac.mrd && ac.mrd.displayStatus === 'MFDI' ? `<div class='mfd issue'>MFD</div>` :
						ac.mrd && (ac.mrd.displayStatus === 'MFDAPP' || ac.mrd.displayStatus === 'MFDARR') ? `<div class='mfd broken'>MFD</div>` :
							`<div class="mrd"><img id="mrd" class="` + mrdClass + `" src="` + mrdImage + `"><span class="parking-duration">` + parkingDuration + `</span></div>`
					}
										</div>
									</div>
									<div class="extension">
										<div class="workload">
										<img id="plane" class="workload-bg" src="`+ workloadImage + `">
										</div>
										<div class="icons-container">
											<div class="icons">
											<img class="workload-bg" id="plane" src="`+ ronImage + `">
											</div>
											<div class="icons">
											<img id="etops" src="`+ etopsImage + `" />
											</div>
										</div>
									</div>											
								</div>
							</div>
							<div class="`+ gateCss + `">${location.locationName}</div>
						</div>`;
				break;
			case 'mini': dataDisplay = `<div class="marker-mini ` + oosClassMini + `"><div class="` + classBrokenMRD15 + `-mini"></div></div>`
				break;
			default: break;
		}
		const m = marker({
			latlng: latlng,
			map: map,
			html: dataDisplay
		});
		m.addListener("click", () => {
			handleMarkerClick(ac);
		});
		localMarkers.push(m);
	});
	leaving.forEach(l => {
		const location = l.location;
		const ac = l.flifo;

		const isApu = isApuOn(ac);

		const latlng = new window.google.maps.LatLng(location.lat, location.lng);
		let dataDisplay = ``;
		const hasMrd = ac.mrd === null ? false : ac.mrd.hasMrd

		let flightImage = getFlightImage(ac.nextIsStar, hasMrd);
		let workloadImage = getWorkloadImage(ac.workloadWrench);
		let etopsImage = ac.nextIsEtops ? `${process.env.PUBLIC_URL}/images/ETOPS-v2.svg` : "";
		let mrdImage = !hasMrd ? `${process.env.PUBLIC_URL}/images/mrd-broken.svg` : `${process.env.PUBLIC_URL}/images/MRD-green.svg`;
		let ronImage = ac.isRon ? `${process.env.PUBLIC_URL}/images/bluemoon.svg` : "";

		var locdirection = location.direction !== null ? location.direction.trim() : '';
		var gateCss = location.locationName.length > 4 ? isApu !== "N" ? 'marker-gate apu-on-h gate-long-name' : 'marker-gate gate-long-name' : isApu !== "N" ? 'marker-gate apu-on-h gate-short-name' : 'marker-gate gate-short-name';
		switch (zoomlevel) {
			case 'max':
			case 'mega': dataDisplay = `<img class="plane-animate animate-fly" src="` + flightImage + `"><div><div class="marker-` + locdirection + `-` + zoomlevel + ` hide-me">
											<div class="main">
												<img id="plane" src="`+ flightImage + `">
												<div class="tailnumber">${ac.tailNumber}</div>
												<div class="mrd"><img id="mrd" src="`+ mrdImage + `"></div>
											</div>
											<div class="extension">
												<div class="workload">
												<img id="plane" class="workload-bg" src="`+ workloadImage + `">
												</div>
												<div class="icons">
												<img class="workload-bg" id="plane" src="`+ ronImage + `">
												</div>
												<div class="icons">
												<img id="etops" src="`+ etopsImage + `" />
												</div>
											</div>
										</div><div class="`+ gateCss + `">${location.locationName}</div></div>`;
				break;
			case 'mini': dataDisplay = `<div class="marker-mini"><div class="animate-circle-mini"></div></div>`
				break;
			default: break;
		}
		const m = marker({
			latlng: latlng,
			map: map,
			html: dataDisplay
		});
		localMarkers.push(m);
	})
	setMarkers(localMarkers);
	setPreviousFlifo(flifo);
	setSearchedAircraft(null);
}

const loadStationMarkers = () => {
	console.log("load station level markers");
	let localMarkers = markers;
	localMarkers.forEach(element => {
		element.remove();
	});
	localMarkers = [];
	if (flifoByStation != null && flifoByStation.length > 0) {
		flifoByStation.forEach(stnFlifo => {
			const latlng = new window.google.maps.LatLng(stnFlifo.lat, stnFlifo.lng);
			const m = marker({
				latlng: latlng,
				map: map,
				html: `<div><div class="marker-station"><div class="station"><span>` + stnFlifo.station + `</span></div></div></div>`
			});
			m.addListener("click", () => {
				handleStationMarkerClick(stnFlifo);
			});
			localMarkers.push(m);
		});
		setMarkers(localMarkers);
	}
}

useEffect(() => {
	setDashboardMap();
}, []);

useEffect(() => {
	if (stationProfile !== null && stationProfile.isValidStation && map !== null) {
		map.setOptions({ minZoom: 15 });
		stationProfile.overlays.forEach(overlay => {
			var ol = new window.google.maps.Polygon({
				id: "test",
				paths: overlay.boundaries,
				strokeColor: '#b5b0a8',
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: '#b5b0a8',
				fillOpacity: 0.75
			});
			//click event for overlays
			// window.google.maps.event.addListener(ol, 'click', function(event) {
			// 	console.log("overlay clicked",ol.indexID);
			// });
			ol.setMap(map);
		});
		if (stationProfile.currentStation !== null) {
			updateFlifo(stationProfile.currentStation);

			const interval = setInterval(() => updateFlifo(stationProfile.currentStation), 90000);
			return () => clearInterval(interval);
		}
	}
}, [stationProfile]);

useEffect(() => {
	if (map !== null) {
		if(!home){
		if (zoomlevel === 'US') {
			let localMarkers = markers;
			localMarkers.forEach(element => {
				element.remove();
			});
			map.setZoom(5);
			var latLng = new window.google.maps.LatLng(39.648562, -100.445360);
			map.setCenter(latLng);
			map.setOptions({ zoomControl: false, mapTypeControl: false });
		}
		else if (zoomlevel === 'station') {
			map.setOptions({ zoomControl: true, mapTypeControl: true });
			loadStationMarkers()
		}
		else {
			console.log('level changed 1', flifoByStation);
			map.setOptions({ zoomControl: true, mapTypeControl: true });
			if(flifo.length>0){
			loadMaps();
			}
		}
	}
	}
}, [flifo, zoomlevel,home]);

useEffect(() => {
	if (map !== null) {
		if (zoomlevel === 'station') {
			console.log(flifoByStation);
			loadStationMarkers()
		}
	}
}, [flifoByStation]);

useEffect(() => {
	if(!home){
	if (workArea && stationProfile) {
		// setZoomlevel("mega");	- Naveen added TPA zoom logic		
		const TPAStation = stationProfile && stationProfile.stations && stationProfile.stations.find(station => station.stationCode === 'TPA');
		if (TPAStation && TPAStation.lat === workArea.lat && TPAStation.lng === workArea.lng) {
			map.setZoom(17);
		}
		else {
			map.setZoom(18);
		}
		if (map) {
			map.panTo(new window.google.maps.LatLng(workArea.lat, workArea.lng));
			map.setOptions({ minZoom: 15 });
			const currentac = workArea.aircraft;
			if (currentac) {
				const isApu = isApuOn(currentac);

				setSearchedAircraft(currentac);
				const localMarkers = markers;
				const isStoredAircraft = currentac.storageStatus !== null && currentac.storageStatus !== 'Returned to Service';
				const isUnroutedAircraft = !isStoredAircraft && currentac.nextScheduleDepartureLocal === null;
				const flightImage = getFlightImage(currentac.nextIsStar, isStoredAircraft, isUnroutedAircraft);
				const flightImageClass = isStoredAircraft ? 'stored-aircraft' : '';
				const workloadImage = getWorkloadImage(currentac.workloadWrench);
				const etopsImage = isStoredAircraft ? '' : (currentac.isEtops ? `${process.env.PUBLIC_URL}/images/ETOPS-v2.svg` : "");
				const mrdImage = isStoredAircraft ? '' : (GetMrdStatus(currentac.mrd) === 'broken' ? `${process.env.PUBLIC_URL}/images/mrd-broken.svg` : `${process.env.PUBLIC_URL}/images/MRD-green.svg`);
				const ronImage = currentac.isRon ? `${process.env.PUBLIC_URL}/images/bluemoon.svg` : '';

				const classBrokenMRD15 = isMRDBroken15(currentac) ? 'animate-circle' : '';
				const oosClass = currentac.oos ? 'oos' : '';
				const parkingDuration = isStoredAircraft && currentac.parkingDuration !== null && currentac.parkingDuration !== '' ? GetShortForm(currentac.parkingDuration) : '';
				const mrdClass = parkingDuration !== '' ? 'mrd-none' : '';

				const locdirection = currentac.tows && currentac.tows.length > 0 && currentac.tows[0].location ?
					currentac.tows[0].location.direction.trim() :
					currentac.location.direction !== null ?
						currentac.location.direction.trim() : '';
				
				const gateCss = currentac.tows && currentac.tows.length > 0 && currentac.tows[0].location ?
				currentac.tows[0].location.locationName.length > 4 ? isApu !== "N" ? 'marker-gate apu-on-h gate-long-name' : 'marker-gate gate-long-name' : isApu !== "N"  ? 'marker-gate apu-on-h gate-short-name' : 'marker-gate gate-short-name' :
				currentac.location.locationName.length > 4 ? isApu !== "N" ? 'marker-gate apu-on-h gate-long-name' : 'marker-gate gate-long-name' : isApu !== "N" ? 'marker-gate apu-on-h gate-short-name' : 'marker-gate gate-short-name';
				const acloc = currentac.tows && currentac.tows.length > 0 && currentac.tows[0].location ?
					currentac.tows[0].location.locationName : currentac.location.locationName;
				const markerhtml = `<div>
											<div class="marker-`+ locdirection + '-' + zoomlevel + oosClass + ` magnify-marker">
												<div class="main">
													<div class="`+ flightImageClass + `">
														<div class="`+ classBrokenMRD15 + `"></div>
														<img id="plane" src="`+ flightImage + `">
													</div>
													<div>
														<div class="tailnumber">${currentac.tailNumber}</div>
														${currentac.mrd && currentac.mrd.displayStatus === 'MFDI' ? `<div class='mfd issue'>MFD</div>` :
						currentac.mrd && (currentac.mrd.displayStatus === 'MFDAPP' || currentac.mrd.displayStatus === 'MFDARR') ? `<div class='mfd broken'>MFD</div>` :
							`<div class="mrd"><img id="mrd" class="` + mrdClass + `" src="` + mrdImage + `"><span class="parking-duration">` + parkingDuration + `</span></div>`
					}
													</div>
												</div>
												<div class="extension">
													<div class="workload">
													<img id="plane" class="workload-bg" src="`+ workloadImage + `">
													</div>
													<div class="icons-container">
														<div class="icons">
														<img class="workload-bg" id="plane" src="`+ ronImage + `">
														</div>
														<div class="icons">
														<img id="etops" src="`+ etopsImage + `" />
														</div>
													</div>
												</div>											
											</div>
											<div class="`+ gateCss + `">${acloc}</div>
										</div>`;
				const m = marker({
					latlng: new window.google.maps.LatLng(workArea.lat, workArea.lng),
					map: map,
					html: markerhtml
				});
				m.addListener("click", () => {
					handleMarkerClick(currentac);
				});
				localMarkers.push(m);
				setMarkers(localMarkers);
			}
		}
	}
	}
}, [workArea, stationProfile,home]);

return (
	<div id='google-map' className="map-container" />
);
}

export default Map;